import React from 'react'
import View from "../framework/alto/ui/view";

const NotFoundPage = () => (
    <View>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </View>
)

export default NotFoundPage
